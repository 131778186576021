.subscriptionCardContents {
  margin-top: 2rem;
  padding: 1rem;
}

.subscriptionButton {
  font: inherit;
  cursor: pointer;
  background-color: #77002e;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #77002e;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.subscriptionButton:disabled {
  background-color: gray;
  border-color: lightgray;
}

.subscribeInfo {
  font-size: 1.25rem;
}

.subscribeFormTitle {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.subscribeFormLabel {
  font-size: 1.25rem;
  /* font-weight: bolder; */
  /* margin-top: 1rem; */
  /* margin-bottom: 0.5rem; */
}

.subscribeFormCardElement {
  font-size: 1.25rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.75rem;
  width: 95%;
  /* margin-top: 1rem; */
  margin-bottom: 2rem;
}

.subscribeFormInput {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.25rem;
  width: 90%;

  font-size: 1.25rem;
  margin-bottom: 2rem;
}
