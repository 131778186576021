.subscriptionCardContents {
  margin-top: 2rem;
  padding: 1rem;
}

.subscriptionCancelContents {
  margin-bottom: 2rem;
  font-size: 1.25rem;
}

.subscriptionButton {
  font: inherit;
  cursor: pointer;
  background-color: #77002e;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #77002e;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.subscriptionButton:disabled {
  background-color: gray;
  border-color: lightgray;
}

.subscriptionCancelButton {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border: 1px solid gray;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.subscriptionCancelButton:disabled {
  background-color: gray;
  border-color: lightgray;
  color: white;
}