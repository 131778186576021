.form {
  padding: 1rem;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;

  margin-top: 1rem;
}

.control input,
.control textarea {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.25rem;
  width: 100%;
}

.actions {
  margin-top: 1rem;
  text-align: right;
}

.actions button {
  font: inherit;
  cursor: pointer;
  background-color: #77002e;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #77002e;
  border-radius: 4px;
  font-weight: bold;
  
  margin-left: 1rem;
}

.actions button:hover,
.actions button:active {
  background-color: #a50e48;
  border-color: #a50e48;
}

.actions button:disabled {
  background-color: gray;
  border-color: lightgray;
}

.timeInputContainer {
  display: inline-flex;
  align-items: center;
}

.timeSelect {
  /* height: 2rem; */
  height: auto;
  /* width: 4rem; */
  width: auto;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.timeSeparator {
  /* height: 2rem; */
  height: auto;
  /* width: 4rem; */
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.timeInput {
  /* height: 2rem; */
  height: auto;
  width: 4rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.resultMessage {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 1rem;
}

.actionSelectButtons {
  display: flex;
}

.reserveButtons {
  display: flex;
  justify-content: space-between;
}

.resetButton  {
  font: inherit;
  cursor: pointer;
  /* background-color: #77002e; */
  /* color: white; */
  padding: 0.5rem 1.5rem;
  border: 1px solid;
  border-radius: 4px;
  font-weight: bold;
  /* margin-left: 1rem; */
  margin-top: 1rem;
}

.selectActionTitle {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 1.25rem;
}

.inputError {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}