.userRoomListTitle {
  font-size: 2rem;
  text-align: center;
}

.userRoomListDescription {
  padding: 1rem;
}

.userRoomListShowFilter {
  padding: 1rem;
  /* cursor: pointer; */
  font-size: 1.25rem;
}

.userRoomListShowFilterButton {
  cursor: pointer;
}
