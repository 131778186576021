.subNav {
  padding: 0.5rem
}

.subNavLogoutButton {
  text-align: right;
}

.subNavAuthButton {
  font-size: 1.25rem;
  border: 0.5px solid lightgray;
  border-radius: 2px;
  margin-left: 1rem;
  padding: 0.25rem;
}

.subNavAuthButtonIcon {
  margin-right: 0.25rem;
}

.subNavSpacer {
  margin-bottom: 0.5rem;
}

.subNavTrialAd {
  margin-top: 1rem;
  padding: 0.5rem;
}
.subNavTrialAdTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
  text-decoration: underline 2px;
}