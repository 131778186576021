.paginator__controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginator__control {
  width: 5rem;
  padding: 0.25rem 0;
  margin: 0 1rem;
  border: 1px solid #3b0062;
  background: transparent;
  font: inherit;
  cursor: pointer;
  font-size: 1rem;
  color: #3b0062;
  color: var(--color);
}

.paginator__control:hover,
.paginator__control:active {
  color: #fab83f;
  border-color: #fab83f;
}

.paginator__control:focus {
  outline: none;
}
