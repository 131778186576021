.trialInfoPlanContainer {
  /* min-width: 10rem;
  max-width: 15rem; */
  border: 0.5px solid gray;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.trialInfoPlanPrice {
  font-size: 1.25rem;
}

.priceDetailContainer {
  margin-top: 2rem
}

.priceDetailInfo {
  margin-bottom: 0.5rem;
}