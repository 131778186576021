.roomFilter {
  padding: 1rem;
  margin: 0 0.2rem;
  border: 0.5px solid gray;
  border-radius: 2px;
}

.roomFilterPeriod {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.roomFilterPeriodSeparator {
  margin: 0 1rem;
  display: block;
  font-weight: bold;
}

.roomFilterYearInput {
  width: 6rem;
  margin: 0 0;
  display: inline;
}

.roomFilterMonthSelect {
  width: 8rem;
  margin: 0 0;
  display: inline
}

@media (min-width: 768px) {
  .roomFilterPeriodSeparator {
    display: inline;
  }
}