.header {
  width: 100%;
  /* height: 5rem; */
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #77002e;
  /* background-color: rgba(0, 0, 0, 0.75); */
  /* padding: 0 10%; */
} 

.logo {
  /* font-size: 2rem; */
  color: white;
  font-weight: bold;
  padding-left: 1rem;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 3rem;
}

.header a {
  text-decoration: none;
  /* font-size: 1.5rem; */
  font-weight: bold;
  color: #fcb8d2;
  color: white;
}

.header a:hover,
.header a:active,
.header a.active {
  color: white;
}

.header nav {
  padding-right: 1rem;
}
