.footer {
  width: 100%;
  /* height: 5rem; */
  min-height: 3rem;
  /* display: flex; */
  align-items: center;
  /* justify-content: space-between; */
  /* background-color: #77002e; */
  background-color: whitesmoke;
  /* padding: 0 10%; */
  /* position: fixed; */
  /* bottom: 0; */
  margin-top: 1rem;
  padding: 1rem;
  font-size: 0.8rem;
}

.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.footer li {
  margin-left: 3rem;
}

.footer a {
  /* text-decoration: none; */
  /* font-size: 1.5rem; */
  /* font-weight: bold; */
  /* color: #fcb8d2; */
}

.footer a:hover,
.footer a:active,
.footer a.active {
  font-weight: normal;
  text-decoration: none;
}

.footer nav {
  padding-right: 1rem;
}

.footerLink {
  margin-left: 1rem;
}

.footerContent {
  margin-top: 1rem;
}