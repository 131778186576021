

.mainNavigationIcon {
  margin-right: 0.25rem;
}

.navListContainer {
  display:flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: baseline;
  /* align-items: center, */
}

.mobileNavList {
  margin-top: 1rem;
  margin-bottom: 1rem;
}