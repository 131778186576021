.notification {
  width: 100%;
  /* height: 3rem; */
  background-color: #1a8ed1;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 10%;
  align-items: center;
  color: white;

  position: fixed;
  background-color: gray;
  bottom: 10%;
  z-index: 10;
  width: 40%;
  left: 20%;
  right: 20%
}

.notification h2,
.notification p {
  font-size: 1rem;
  margin: 0;
}

.error {
  background-color: #690000;
  opacity: 0.9;
}

.success {
  background-color: #1ad1b9;

  background-color: gray;
  color: white;
  opacity: 0.9;
}
