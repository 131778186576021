.userRoomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.userRoom {
  /* width: 40rem; */
  max-width: 100%;
  min-height: 100vh;
  /* max-width: 40rem; */
}

@media (min-width: 768px) {
  /* .userRoomContainer {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  } */
}