/* .menuBtn span {
  width: 100%;
  height: 3px;
  background-color: white;
} */

.mobileNavigation {
  position: fixed;
  /* font-size: 2.5rem; */
  background: white;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100vh;
  width: 30rem;
  max-width: 60%;
  /* background-color: whitesmoke; */
  background-color: rgba(0, 0, 0, 0.95);

  border: 0.5px solid #999;
  box-shadow: 1px 0 8px rbga(0, 0, 0, 0.26);
  transition: transform 0.3s ease-out;
  /* transform: translateX(100%); */
  padding: 1rem 1rem;

  overflow-y: auto;
  /* display: flex;
  flex-direction: column; */
  /* justify-content: space-between; */
}