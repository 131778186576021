.trialInfoTitle {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
  /* margin-top: 0.5rem; */
  margin-bottom: 2rem;
}

.trialInfoContentsTitle {
  font-size: 1.25rem;
  /* font-weight: bolder; */
  /* text-align: center; */
  /* margin-top: 1rem; */
  margin-bottom: 2rem;
  margin-left: 1.5rem;
  margin-right: 0.5rem;
  display: list-item;
}

.trialInfoContentsContent {
  font-size: 1.25rem;
  font-weight: bold;
}

.trialInfoButton {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  border: 0.5px solid lightgray;
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: 1rem;
  /* margin-top: 1rem; */
  margin-bottom: 3rem;
}

.trialInfoImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 2rem;
}

.trialInfoImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 480px) {
  .trialInfoImageContainer {
    flex-direction: row;
  }

  .trialInfoImage {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
}