.authLinkButton {
  font-size: 1.25rem;
  border: 0.5px solid lightgray;
  border-radius: 2px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}

.message {
  font-weight: bolder;
}

.infoTitle {
  margin-top: 0.5rem;
}

.infoOR {
  /* text-align: center; */
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}