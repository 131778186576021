.languageList {
  list-style: none;
  padding: 1.5rem;
  /* width: 80% */
}

.languageList li {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  cursor: pointer;
}

.languageClose {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;
  cursor: pointer;
}

.language ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.languageCode {
  font-weight: bolder;
  margin-right: 1rem;
}

.languageName {
  font-size: 0.75rem;
  font-weight: bolder;
}

.languageBody {
  cursor: pointer;
}