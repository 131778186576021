.main {
  margin: 3rem auto;
  /* margin: 1rem auto; */
  width: 90%;
  width: 100%;
  max-width: 40rem;
}

.AppStretch {
  min-height: 100vh;
}

.AppContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}