.userInfo {
  padding: 1rem;
}

.userInfoTitle {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.userInfoElements {
  margin-bottom: 2rem;
  margin-left: 0.5rem;
}

.userInfoContent {
  font-weight: bolder;
}