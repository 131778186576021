.aboutRemeetContent {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  font-size: 1.25rem;
}

.aboutRemeetTitle {
  padding-top: 3rem;
  font-size: 1.25rem;
  font-weight: bold;
  cursor: pointer;
}

.aboutRemeetImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 0;
}

.aboutRemeetImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 480px) {
  .aboutRemeetImageContainer {
    flex-direction: row;
  }

  .aboutRemeetImage {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }
}