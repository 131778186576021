.control {
  margin-bottom: 0.5rem;
}

.title {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
}

.label {
  font-weight: bold;
  margin-top: 0.5rem;
}

.content {
  display: block;
  font-weight: bolder;
  margin-bottom: 0.5rem;
}